import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ethers } from 'ethers';
import { fetchAddressDetails } from '../api';
import logo from './../assets/images/logo.jpg';

const SUSD_TOKEN_ADDRESS = '0x65C4A0dA0416d1262DbC04BeE524c804205B92e8';

const AddressDetails = () => {
    const { address } = useParams();
    const [details, setDetails] = useState(null);
    const [error, setError] = useState(null);
    const [susdBalance, setSusdBalance] = useState(null);

    useEffect(() => {
        const loadAddressDetails = async () => {
            try {
                const provider = new ethers.JsonRpcProvider('https://chain.scimatic.net');
                const addressDetails = await fetchAddressDetails(address);

                if (addressDetails.isContract) {
                    // Check if it's an ERC20 token
                    const contract = new ethers.Contract(
                        address,
                        [
                            'function name() view returns (string)',
                            'function symbol() view returns (string)',
                            'function totalSupply() view returns (uint256)',
                        ],
                        provider
                    );

                    try {
                        const name = await contract.name();
                        const symbol = await contract.symbol();
                        const totalSupply = ethers.formatUnits(await contract.totalSupply(), 18);

                        setDetails({ ...addressDetails, name, symbol, totalSupply });
                    } catch (err) {
                        console.error('Not an ERC20 token:', err.message);
                        setDetails(addressDetails);
                    }
                } else {
                    // Fetch SUSD token balance for wallet addresses
                    const susdContract = new ethers.Contract(
                        SUSD_TOKEN_ADDRESS,
                        ['function balanceOf(address) view returns (uint256)'],
                        provider
                    );

                    const susdBalanceRaw = await susdContract.balanceOf(address);
                    const formattedSusdBalance = ethers.formatUnits(susdBalanceRaw, 18);

                    setSusdBalance(formattedSusdBalance);
                    setDetails(addressDetails);
                }
            } catch (err) {
                console.error(err.message);
                setError('Failed to fetch address details.');
            }
        };

        loadAddressDetails();
    }, [address]);

    if (error) return <div style={styles.error}>{error}</div>;
    if (!details) return <div style={styles.loading}>Loading...</div>;

    return (
        <div style={styles.container}>
            {/* Header */}
            <header style={styles.header}>
                <Link to="/" style={styles.logoLink}>
                    <img src={logo} alt="Logo" style={styles.logo} />
                </Link>
                <h1 style={styles.title}>🚀 Address Details</h1>
            </header>

            {/* Address Details */}
            <div style={styles.addressDetails}>
                <h2 style={styles.addressTitle}>Address Information</h2>
                <p style={styles.detail}>
                    <strong>Address:</strong> {details.address}
                </p>
                <p style={styles.detail}>
                    <strong>Type:</strong> {details.isContract ? 'Smart Contract' : 'Wallet'}
                </p>
                {!details.isContract && (
                    <>
                        <p style={styles.detail}>
                            <strong>Balance:</strong>  { Number(details.balance).toFixed(4)} SCI
                        </p>
                        <p style={styles.detail}>
                            <strong>SUSD Balance:</strong> {susdBalance || 0} SUSD
                        </p>
                    </>
                )}
                {details.isContract && details.name && (
                    <>
                        <p style={styles.detail}>
                            <strong>Token Name:</strong> {details.name}
                        </p>
                        <p style={styles.detail}>
                            <strong>Symbol:</strong> {details.symbol}
                        </p>
                        <p style={styles.detail}>
                            <strong>Total Supply:</strong> {details.totalSupply} {details.symbol}
                        </p>
                    </>
                )}
            </div>

            {/* Footer */}
            <footer style={styles.footer}>
                <p style={styles.footerText}>
                    © 2021-{new Date().getFullYear()} SciMatic Blockchain Explorer. All rights reserved.
                </p>
            </footer>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#121212',
        color: '#E0E0E0',
        minHeight: '100vh',
        padding: '20px',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
        paddingTop: '10px',
    },
    logo: {
        width: '80px',
        height: 'auto',
        marginBottom: '10px',
    },
    logoLink: {
        display: 'inline-block',
        marginBottom: '10px',
    },
    title: {
        fontSize: '2.5rem',
        color: '#00D1FF',
        margin: '10px 0',
    },
    addressDetails: {
        backgroundColor: '#1E1E1E',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        marginTop: '20px',
    },
    addressTitle: {
        fontSize: '1.5rem',
        marginBottom: '20px',
        color: '#00D1FF',
    },
    detail: {
        fontSize: '1rem',
        margin: '10px 0',
        borderBottom: '1px solid #333',
        paddingBottom: '5px',
    },
    loading: {
        textAlign: 'center',
        color: '#00D1FF',
        fontSize: '1.5rem',
    },
    error: {
        textAlign: 'center',
        color: 'red',
        fontSize: '1.5rem',
    },
    footer: {
        marginTop: '30px',
        textAlign: 'center',
        padding: '10px',
        backgroundColor: '#1E1E1E',
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    },
    footerText: {
        fontSize: '0.9rem',
        color: '#A0A0A0',
    },
};

export default AddressDetails;
