import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchBlockDetails } from '../api';
import logo from './../assets/images/logo.jpg';

const BlockDetails = () => {
    const { hash } = useParams();
    const [block, setBlock] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadBlockDetails = async () => {
            try {
                const blockDetails = await fetchBlockDetails(hash);
                setBlock(blockDetails);
            } catch (err) {
                console.error(err.message);
                setError('Failed to fetch block details.');
            }
        };

        loadBlockDetails();
    }, [hash]);

    if (error) return <div style={styles.error}>{error}</div>;
    if (!block) return <div style={styles.loading}>Loading...</div>;

    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <Link to="/" style={styles.logoLink}>
                    <img src={logo} alt="Logo" style={styles.logo} />
                </Link>
                <h1 style={styles.title}>🚀 Scimatic Blockchain Explorer</h1>
            </header>
            <div style={styles.content}>
                <h1 style={styles.title}>Block #{parseInt(block.number, 16)}</h1>
                <div style={styles.blockDetails}>
                    <p style={styles.detail}><strong>Hash:</strong> {block.hash}</p>
                    <p style={styles.detail}><strong>Transactions:</strong> {block.transactions.length}</p>
                    <p style={styles.detail}><strong>Timestamp:</strong> {new Date(parseInt(block.timestamp, 16) * 1000).toLocaleString()}</p>
                    <p style={styles.detail}><strong>Miner:</strong> {block.miner}</p>
                    <p style={styles.detail}><strong>Gas Used:</strong> {parseInt(block.gasUsed, 16)}</p>
                    <p style={styles.detail}><strong>Gas Limit:</strong> {parseInt(block.gasLimit, 16)}</p>
                    <p style={styles.detail}><strong>Size:</strong> {parseInt(block.size, 16)} bytes</p>
                </div>

                {block.transactions.length > 0 && (
    <div style={styles.transactionSection}>
        <h2 style={styles.transactionTitle}>Transactions</h2>
        <ul style={styles.transactionList}>
            {block.transactions.map((tx) => {
                // If `tx` is an object, extract the transaction hash
                const transactionHash = typeof tx === 'string' ? tx : tx.hash;
                return (
                    <li key={transactionHash} style={styles.transactionItem}>
                        <Link to={`/transaction/${transactionHash}`} style={styles.transactionLink}>
                            {transactionHash.slice(0, 10)}...{transactionHash.slice(-10)}
                        </Link>
                    </li>
                );
            })}
        </ul>
    </div>
)}

            </div>
            <footer style={styles.footer}>
                <p style={styles.footerText}>
                    © 2021-{new Date().getFullYear()} SciMatic Hybrid Blockchain. All rights reserved.
                </p>
            </footer>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#121212',
        color: '#E0E0E0',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#1E1E1E',
        borderBottom: '1px solid #333',
    },
    logo: {
        width: '80px',
        height: 'auto',
    },
    logoLink: {
        display: 'inline-block',
        marginBottom: '10px',
    },
    title: {
        fontSize: '2rem',
        color: '#00D1FF',
        margin: '10px 0',
    },
    content: {
        flex: 1,
        padding: '20px',
    },
    blockDetails: {
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: '#1E1E1E',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    detail: {
        fontSize: '1rem',
        marginBottom: '10px',
        borderBottom: '1px solid #333',
        paddingBottom: '5px',
    },
    transactionSection: {
        marginTop: '20px',
    },
    transactionTitle: {
        fontSize: '1.5rem',
        marginBottom: '10px',
        color: '#00D1FF',
    },
    transactionList: {
        listStyleType: 'none',
        padding: 0,
    },
    transactionItem: {
        marginBottom: '10px',
    },
    transactionLink: {
        color: '#00D1FF',
        textDecoration: 'none',
        fontSize: '1rem',
        transition: 'color 0.3s',
    },
    loading: {
        textAlign: 'center',
        fontSize: '1.5rem',
        color: '#00D1FF',
        marginTop: '20px',
    },
    error: {
        textAlign: 'center',
        fontSize: '1.5rem',
        color: 'red',
        marginTop: '20px',
    },
    footer: {
        textAlign: 'center',
        padding: '10px',
        backgroundColor: '#1E1E1E',
        borderTop: '1px solid #333',
    },
    footerText: {
        fontSize: '0.9rem',
        color: '#A0A0A0',
    },
};

export default BlockDetails;
