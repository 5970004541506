import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchBlocks } from '../api';
import logo from './../assets/images/logo.jpg';

const Dashboard = () => {
    const [blocks, setBlocks] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchError, setSearchError] = useState(null);
    const [newBlockHash, setNewBlockHash] = useState(null);
    const navigate = useNavigate();
    const fetchedBlocksRef = useRef([]);

    const loadBlocks = async () => {
        try {
            const fetchedBlocks = await fetchBlocks();

            if (fetchedBlocks.length > 0 && fetchedBlocks[0].hash !== fetchedBlocksRef.current[0]?.hash) {
                setNewBlockHash(fetchedBlocks[0].hash);
            }

            fetchedBlocksRef.current = fetchedBlocks;
            setBlocks(fetchedBlocks);
        } catch (error) {
            console.error('Error loading blocks:', error.message);
        }
    };

    useEffect(() => {
        loadBlocks();
        const interval = setInterval(() => loadBlocks(), 5000);
        return () => clearInterval(interval);
    }, []);

    const handleSearch = (event) => setSearchQuery(event.target.value);

    const performSearch = () => {
        if (!searchQuery) {
            setSearchError('Please enter a valid search query.');
            return;
        }

        if (searchQuery.length === 66 && searchQuery.startsWith('0x')) {
            navigate(`/transaction/${searchQuery}`);
        } else if (searchQuery.length === 42 && searchQuery.startsWith('0x')) {
            navigate(`/address/${searchQuery}`);
        } else {
            setSearchError('Invalid search query. Please enter a valid transaction hash or wallet address.');
        }
    };

    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <Link to="/" style={styles.logoLink}>
                    <img src={logo} alt="Logo" style={styles.logo} />
                </Link>
                <h1 style={styles.title}>🚀 Scimatic Blockchain Explorer</h1>
                <div style={styles.searchBox}>
                    <input
                        type="text"
                        placeholder="Search by address, transaction hash..."
                        value={searchQuery}
                        onChange={handleSearch}
                        style={styles.searchInput}
                    />
                    <button onClick={performSearch} style={styles.searchButton}>Search</button>
                </div>
                {searchError && <p style={styles.error}>{searchError}</p>}
            </header>
            <div style={styles.blockList}>
                {blocks.length > 0 ? (
                    blocks.map((block) => (
                        <div
                            key={block.hash}
                            style={{
                                ...styles.blockCard,
                                animation: block.hash === newBlockHash ? 'fadeIn 0.8s ease-in-out' : 'none',
                            }}
                        >
                            <h2 style={styles.blockTitle}>
                                <Link to={`/block/${block.hash}`} style={styles.blockLink}>
                                    Block #{parseInt(block.number, 16)}
                                </Link>
                            </h2>
                            <p style={styles.blockDetails}>Hash: {block.hash.slice(0, 10)}...</p>
                            <p style={styles.blockDetails}>Transactions: {block.transactions.length}</p>
                        </div>
                    ))
                ) : (
                    <p style={styles.noBlocks}>No blocks available.</p>
                )}
            </div>
            <footer style={styles.footer}>
                <p style={styles.footerText}>
                    © {new Date().getFullYear()} SciMatic Blockchain Explorer. All rights reserved.
                </p>
            </footer>

        </div>
    );
};

const styles = {
    container: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#121212',
        color: '#E0E0E0',
        minHeight: '100vh',
        padding: '20px',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
        paddingTop: '10px',
    },
    logo: {
        width: '80px', // Adjust logo size
        height: 'auto',
        marginBottom: '10px',
        maxWidth: '100%', // Ensures it doesn't overflow on smaller screens

    },
    blockLink: {
        color: '#00D1FF',
        textDecoration: 'none',
        transition: 'color 0.3s',
        '&:hover': {
            color: '#00FF88',
        },
    },

    title: {
        fontSize: '2rem', // Adjust font size for the title
        color: '#00D1FF',
        margin: '10px 0',
    },
    searchInput: {
        padding: '10px',
        width: '100%', // Adjust to full width on small screens
        maxWidth: '300px', // Limit max width for larger screens
        borderRadius: '5px',
        border: '1px solid #00D1FF',
        marginRight: '10px',
        color: '#121212',
    },
    searchBox: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap', // Adjust for small screens
    },

    searchButton: {
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: '#00D1FF',
        color: '#121212',
        border: 'none',
        cursor: 'pointer',
    },

    blockList: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', // Responsive grid
        gap: '20px',
        width: '100%',
        padding: '20px',
    },
    '@keyframes fadeIn': {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },
    blockCard: {
        animation: 'fadeIn 0.8s ease-in-out',
        backgroundColor: '#1E1E1E',
        borderRadius: '10px',
        padding: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        transition: 'transform 0.3s, box-shadow 0.3s',
    },


    blockTitle: {
        fontSize: '1.2rem', // Adjust font size for block titles
        marginBottom: '10px',
        color: '#00D1FF',
    },
    blockDetails: {
        fontSize: '0.9rem',
        color: '#A0A0A0',
    },
    noBlocks: {
        textAlign: 'center',
        color: '#FF6F61', // Stand out error color
        marginTop: '20px',
        fontSize: '1rem',
    },
    error: {
        color: 'red',
        textAlign: 'center',
        marginTop: '10px',
    },

    footer: {
        marginTop: '30px',
        textAlign: 'center',
        padding: '10px',
        backgroundColor: '#1E1E1E',
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    },
    footerText: {
        fontSize: '0.9rem',
        color: '#A0A0A0',
    },
    

};


export default Dashboard;
