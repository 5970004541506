import axios from 'axios';

const SCIMATIC_RPC = 'https://chain.scimatic.net';

export const makeRpcCall = async (method, params = []) => {
    try {
        const response = await axios.post(
            SCIMATIC_RPC,
            {
                jsonrpc: '2.0',
                method,
                params,
                id: 1,
            },
            { headers: { 'Content-Type': 'application/json' } }
        );
        return response.data.result;
    } catch (error) {
        console.error(`RPC call failed for method ${method}:`, error.message);
        throw new Error(`Failed to fetch data: ${error.message}`);
    }
};

export const fetchBlocks = async (start = 0, count = 10) => {
    const latestBlockHex = await makeRpcCall('eth_blockNumber');
    const latestBlock = parseInt(latestBlockHex, 16);

    const blocks = [];
    for (let i = start; i < start + count; i++) {
        const blockHex = `0x${(latestBlock - i).toString(16)}`;
        const block = await makeRpcCall('eth_getBlockByNumber', [blockHex, true]);
        blocks.push(block);
    }

    return blocks;
};

export const fetchTransactionDetails = async (hash) => {
    return await makeRpcCall('eth_getTransactionByHash', [hash]);
};

export const fetchBlockDetails = async (hash) => {
    return await makeRpcCall('eth_getBlockByHash', [hash, true]);
};

export const fetchAddressDetails = async (address) => {
    const balanceHex = await makeRpcCall('eth_getBalance', [address, 'latest']);
    const code = await makeRpcCall('eth_getCode', [address, 'latest']);
    const isContract = code && code !== '0x';
    const balance = parseInt(balanceHex, 16) / 1e18;

    return { address, balance, isContract };
};
