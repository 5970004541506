import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchTransactionDetails } from '../api';
import { ethers } from 'ethers'; // Import ethers for conversion
import logo from './../assets/images/logo.jpg';

const TransactionDetails = () => {
    const { hash } = useParams();
    const [transaction, setTransaction] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadTransactionDetails = async () => {
            try {
                const transactionDetails = await fetchTransactionDetails(hash);

                // Convert value to Ether using ethers.js
                transactionDetails.value = ethers.formatUnits(transactionDetails.value, 'ether');
                setTransaction(transactionDetails);
            } catch (err) {
                console.error(err.message);
                setError('Failed to fetch transaction details.');
            }
        };

        loadTransactionDetails();
    }, [hash]);

    if (error) return <div style={styles.error}>{error}</div>;
    if (!transaction) return <div style={styles.loading}>Loading...</div>;

    return (
        <div style={styles.container}>
            {/* Header */}
            <header style={styles.header}>
                <Link to="/" style={styles.logoLink}>
                    <img src={logo} alt="Logo" style={styles.logo} />
                </Link>
                <h1 style={styles.title}>🚀 Transaction Details</h1>
            </header>

            {/* Transaction Details */}
            <div style={styles.transactionDetails}>
                <h2 style={styles.transactionTitle}>Transaction Information</h2>
                <p style={styles.detail}>
                    <strong>Hash:</strong> {transaction.hash}
                </p>
                <p style={styles.detail}>
                    <strong>From:</strong> {transaction.from}
                </p>
                <p style={styles.detail}>
                    <strong>To:</strong> {transaction.to || 'Contract Creation'}
                </p>
                <p style={styles.detail}>
                    <strong>Value:</strong> {transaction.value} SCI
                </p>
                {/* Add more transaction details as necessary */}
            </div>

            {/* Footer */}
            <footer style={styles.footer}>
                <p style={styles.footerText}>
                    © 2021-{new Date().getFullYear()} SciMatic Blockchain Explorer. All rights reserved.
                </p>
            </footer>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#121212',
        color: '#E0E0E0',
        minHeight: '100vh',
        padding: '20px',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
        paddingTop: '10px',
    },
    logo: {
        width: '80px',
        height: 'auto',
        marginBottom: '10px',
    },
    logoLink: {
        display: 'inline-block',
        marginBottom: '10px',
    },
    title: {
        fontSize: '2.5rem',
        color: '#00D1FF',
        margin: '10px 0',
    },
    transactionDetails: {
        backgroundColor: '#1E1E1E',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        marginTop: '20px',
    },
    transactionTitle: {
        fontSize: '1.5rem',
        marginBottom: '20px',
        color: '#00D1FF',
    },
    detail: {
        fontSize: '1rem',
        margin: '10px 0',
        borderBottom: '1px solid #333',
        paddingBottom: '5px',
    },
    loading: {
        textAlign: 'center',
        color: '#00D1FF',
        fontSize: '1.5rem',
    },
    error: {
        textAlign: 'center',
        color: 'red',
        fontSize: '1.5rem',
    },
    footer: {
        marginTop: '30px',
        textAlign: 'center',
        padding: '10px',
        backgroundColor: '#1E1E1E',
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    },
    footerText: {
        fontSize: '0.9rem',
        color: '#A0A0A0',
    },
};

export default TransactionDetails;
