import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import BlockDetails from './components/BlockDetails';
import TransactionDetails from './components/TransactionDetails';
import TokenDetails from './components/TokenDetails';
import AddressDetails from './components/AddressDetails';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/block/:hash" element={<BlockDetails />} />
                <Route path="/transaction/:hash" element={<TransactionDetails />} />
                <Route path="/address/:address" element={<AddressDetails />} />
                <Route path="/token/:address" element={<TokenDetails />} />
            </Routes>
        </Router>
    );
};

export default App;
